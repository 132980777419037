import React, { useRef, useState } from 'react'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import { Formik, Form, useField } from 'formik'
import * as Yup from 'yup'
import Seo from '../../component/Seo'
import Layout from '../../component/Layout/Layout'
import './contact-us.scss'

const AboutPage = () => {
  const [viewMessage, setViewMessage] = useState(false)
  const reRef = useRef()

  const queryfy = obj => {

    if (typeof obj === 'number') {
      return obj
    }

    if (typeof obj !== 'object' || Array.isArray(obj)) {
      return JSON.stringify(obj)
    }

    let props = Object.keys(obj).map(key =>
      `${key}:${queryfy(obj[key])}`
    ).join(', ')

    return `{${props}}`
  }

  const MyTextInput = ({ ...props }) => {
    const [field, meta] = useField(props)
    return (
      <>
        <input className="contact-us__form__input" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    )
  }

  return (
    <Layout>
      <Seo title="Contact us Mortgage"/>
      <section className="contact-us">
        <div className="contact-us__inner">
          <Formik
            initialValues={{
              name: '',
              email: '',
              message: '',
            }}
            validationSchema={Yup.object({
              name: Yup.string().max(20, 'Must be 15 characters or less').required('Required field'),
              email: Yup.string().email('Invalid email address').required('Required field'),
              message: Yup.string().required('Required field')

            })}
            onSubmit={async (values, { resetForm }) => {
              values.token = await reRef.current.executeAsync()
              const query = `
                mutation {
                  sendMail(data: ${queryfy(values)})
                }`

              await axios.post('https://backend.ecdevstudio.com/graphql', { query: query })
                .then(function (response) {
                  setViewMessage(true)

                  setTimeout(() => {
                    setViewMessage(false)
                  }, 2500)
                })
                .catch(function (error) {
                  console.log(error)
                })
              reRef.current.reset()
              resetForm({})
            }}
          >
            <Form className="contact-us__form">
            <span className="contact-us__form__tagline tagline">
              CONTACT US
            </span>
              <h1 className="contact-us__form__headline">
                Send us a message
              </h1>
              <div className="contact-us__form__item contact-us__form__item--short">
                <label htmlFor="name" className="contact-us__form__label">
                  What is your name?
                </label>
                <MyTextInput
                  name="name"
                  type="text"
                  placeholder="Francisco d'Anconia"
                />
              </div>
              <div className="contact-us__form__item contact-us__form__item--short">
                <label htmlFor="email" className="contact-us__form__label">
                  Email address
                </label>
                <MyTextInput
                  name="email"
                  type="email"
                  placeholder="example@mail.com"
                />
              </div>
              <div className="contact-us__form__item">
                <label htmlFor="comments" className="contact-us__form__label">
                  Comments
                </label>
                <MyTextInput
                  name="message"
                  type="text"
                  placeholder="Your message"
                />
              </div>
              <button type="submit" className="contact-us__form__submit">
                Send
              </button>
              <div className="recaptcha-container">
                <ReCAPTCHA
                  sitekey="6LcRFp8aAAAAANqElT4Imet73fb16as694O77mae"
                  ref={reRef}
                  size="invisible"
                  className="recaptcha"
                  hl="en"
                />
              </div>
              {
                viewMessage ?
                  <p className="contact-us__form__result">Message has been sent successfully</p>
                  : null
              }
            </Form>
          </Formik>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage